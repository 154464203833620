<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/en-关于盛泰banner.png" class="images">
      </el-carousel-item>
    </el-carousel>
    <!-- 中间内容区域 -->
    <div class="content">
      <h2 class="H2">Quality</h2>
      <li style="list-style-type:square ;">Group System Certification</li>
      <p>Introduction of international standard system as a tool for continuous improvement and quality management.</p>
      <div class="content_1">
        <div v-for="item in imgList" :key="item.id">
          <img :src="item.url" alt="">
          <p>{{ item.title }}</p>
        </div>
      </div>
      <li style="list-style-type:square ;">Quality first, pursuit of excellence.</li>
      <p>Shinetech grasps the quality gate in multiple dimensions and always insists on quality first and the pursuit of excellence.</p>
      <img class="images_1" style="width: 100%;" src="@/assets/about/quality/en-品质中心配图.png" alt="">
      <!-- <div class="images_1">
        <img class="images_1_01" src="@/assets/about/quality/圆.png" alt="">
        <img class="images_1_02" src="@/assets/about/quality/品质中心组件1.png" alt="">
        <h2 class="text_1">盛泰品质中心</h2>
        <div class="text_2">
          <h2>{{ text_2.name1 }}</h2>
          <li v-for="item in text_2.li_top" :key="item.id">{{ item.title }}</li>
          <h2>{{ text_2.name2 }}</h2>
          <li v-for="item in text_2.li_bottom" :key="item.id" style="width: 50%;float: left;">{{ item.title }}
          </li>
        </div>
        <div class="text_3">
          <h2>{{text_3.name1}}</h2>
          <li v-for="item in text_3.list" :key="item.id" style="width: 50%;float: left;">{{item.title}}</li>
        </div>
        <div class="text_4">
          <h2>{{text_4.name1}}</h2>
          <li  style="text-align: left;list-style: none;line-height: 2rem;">{{text_4.title}}</li>
        </div>
        <div class="text_5">
          <h2>{{text_5.name1}}</h2>
          <li style="text-align: left;line-height: 2rem;margin-top: 1.5rem;" v-for="item in text_5.list" :key="item.id">{{item.title}}</li>
        </div>
      </div> -->
      <li style="list-style-type:square ;margin-top: 5rem ;">Group Company Honors</li>
      <p>Insist on improving quality and focus on R&D. Shinetech has won several provincial and municipal honors and received consistent affirmation from customers and society.</p>
      <img style="width: 100%;" src="@/assets/about/quality/en-奖杯组图.png" alt="" />
    </div>
    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/homePage/en-关于盛泰banner.png" class="images">
      </van-swipe-item>
    </van-swipe>
    <!-- 内容区域 -->
    <div class="content">
      <h2>Quality</h2>
      <li class="li_1" style="list-style-type:square ;">Group System Certification</li>
      <p class="p_1">Introduction of international standard system as a tool for continuous improvement and quality management.</p>
      <div class="content_1">
        <div v-for="item in imgList" :key="item.id">
          <img :src="item.url" alt="">
          <p class="p_1">{{ item.title }}</p>
        </div>
      </div>
      <li class="li_1" style="list-style-type:square ;">Quality first, pursuit of excellence.</li>
      <p class="p_1">Shinetech grasps the quality gate in multiple dimensions and always insists on quality first and the pursuit of excellence.</p>
      <img style="width: 100%;" src="@/assets/about/quality/en-品质中心配图.png" alt="">
      <li class="li_1" style="list-style-type:square ;margin-top: 5rem ;">Group Company Honors</li>
      <p class="p_1">Insist on improving quality and focus on R&D. Shinetech has won several provincial and municipal honors and received consistent affirmation from customers and society.</p>
      <img style="width: 100%;" src="@/assets/about/quality/en-奖杯组图.png" alt="" />
    </div>
    <!-- 底部 -->
    <move_foot></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRoute } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
  components: { foot, headers, move_header, move_foot },

  setup() {
    const route = useRoute();
    const state = reactive({
      text_2: { name1: 'Level II Material Management', name2: 'Hazardous Substance Management', li_top: [{ id: 1, title: ' Provider basic information survey--Strictly control the entry of providers' }, { id: 2, title: 'Material selection risk assessment--Strictly control the entry of materials' }, { id: 3, title: ' Regular assessment--Provider assessment and correction' }], li_bottom: [{ id: 1, title: '环保标准收集' }, { id: 2, title: '来料检验' }, { id: 3, title: '研发设计评审' }, { id: 4, title: '环保报告管理' }, { id: 5, title: '供应链管理' }, { id: 6, title: '材料成品送检' }] },
      text_3: { name1: '制程监控', list: [{ id: 1, title: '供应商品质' }, { id: 2, title: '制程品质' }, { id: 3, title: '客户品质' }, { id: 4, title: 'RA实验室' }, { id: 5, title: '来料品质' }, { id: 6, title: '出货品质' }, { id: 7, title: '品质工具' }, { id: 8, title: '品质体系' }] },
      text_4: { name1: '三化一稳定', title: '量管理以“三化一稳定”(管理IT化生产自动化、人员专业化、关键岗位人员稳定)为要求,全面引导供应商能力提升。' },
      text_5: { name1: '实验中心', list: [{ id: 1, title: '可靠性实验中心有可靠性检测,成像分析，机械检测。' }, { id: 2, title: '异常分析中心内设化学分析实验室电性分析实验室、材料分析室、成份分析室、光学分析室。' }, { id: 3, title: '实验中心包含失效分析能力实验室多种仪器,打造高品质产品。' }] },
      imgList: [
        { id: 1, url: require('@/assets/about/quality/ANSI_ESD S20.20-2014.png'), title: 'ANSI_ESD S20.20-2014' },
        { id: 2, url: require('@/assets/about/quality/IECQ符合性证书.png'), title: 'IECQ Certificate of compliance' },
        { id: 3, url: require('@/assets/about/quality/ISO 14001-2015.png'), title: 'ISO 14001-2015' },
        { id: 7, url: require('@/assets/about/quality/ISO 140012015.png'), title: 'ISO 140012015' },
        { id: 4, url: require('@/assets/about/quality/ISO 9001-2015.png'), title: 'ISO 9001-2015' },
        { id: 6, url: require('@/assets/about/quality/ISO 90012015.png'), title: 'ISO 90012015' },
        { id: 8, url: require('@/assets/about/quality/ISO 45001-2018.png'), title: 'ISO 45001-2018' },
        { id: 5, url: require('@/assets/about/quality/ISO 450012018.png'), title: 'ISO 450012018' },
      ]
    });
    onMounted(() => {
      window.scrollTo(0, 0)
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped lang="less">
.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

.box {
  width: 100%;

  .content {
    max-width: 1920px;
    margin: 0 auto;
    width: 70%;

    .images_1 {
      position: relative;
      h2{Letter-spacing:3px;}
      li {
        // list-style-type: none;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        text-indent: 2em;
        margin-top: 10px;
        line-height: 22px;
      }

      div,
      .text_1 {
        position: absolute;
        box-sizing: border-box;
      }

      .text_1 {
        top: 415px;
        left: 120px;
        color: #fff;
      }

      .text_2 {
        text-align: center;
        left: 420px;
        top: 33px;
        padding: 30px;
        width: 400px;
        height: 500px;
      }

      .text_3 {
        color: #fff;
        text-align: center;
        right: 55px;
        top: 0;
          width: 400px;
        height: 350px;
        padding: 30px;
      }

      .text_4 {
        color: #fff;
        text-align: center;
        left: 420px;
        bottom: 0;
          width: 400px;
        height: 320px;
        padding: 30px;
      }

      .text_5 {
       text-align: center;
        right: 35px;
        bottom: 33px;
        padding: 30px;
        width: 400px;
        height: 480px;
        // border: 1px solid #ccc;
      }

      .images_1_01 {
        width: 30%;
        margin-bottom: 312px;

      }

      .images_1_02 {
        width: 70%;
      }
    }

    .content_1 {
      display: flex;
      flex-wrap: wrap;

      div {
        text-align: center;
        box-sizing: border-box;
        padding: 20px;
        flex: 1;
        max-width: 25%;
        min-width: 25%;

        img {
          width: 100%;
        }
      }

    }

    p {
      text-indent: 2em;
      font-size: 20px;
    }

    li {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .H2 {
      margin-top: 50px;
      font-size: 1.7rem;
    }

    .H2::before {
      content: "";
      width: 30%;
      height: 2px;
      background-color: #c7000b;
      position: absolute;
      margin-top: 40px;
      left: 18%;
      transform: translateX(-50%);
      // top:55px;
      min-width: 35px;
      max-width: 35px;
    }
  }
}

.images {
  width: 100%;
  height: auto;
  max-height: 600px;
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;

    .content {
      padding: 30px;

      .li_1 {
        font-size: 65px;
      }

      .p_1 {
        font-size: 45px;
      }

      .content_1 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        div {
          text-align: center;
          box-sizing: border-box;
          padding: 20px;
          flex: 1;
          max-width: 48%;
          min-width: 48%;

          img {
            width: 100%;
          }
        }

      }

      p {
        text-indent: 2em;
        font-size: 20px;
      }

      li {
        font-size: 30px;
        font-weight: bold;
      }

      h2 {
        text-align: center;
        font-size: 80px;
      }

      h2::before {
        content: "";
        width: 30%;
        height: 8px;
        background-color: #c7000b;
        position: absolute;
        margin-top: 100px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100px;
        max-width: 100px;
      }
    }
  }
}
</style>